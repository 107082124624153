<template>
  <v-card class="card-shadow">
    <div class="card-header-padding card-border-bottom">
      <p class="font-weight-600 text-h3 text-typo mb-0">Transaction history</p>
    </div>

    <v-card-text class="px-0 py-0">
      <v-simple-table class="table">
        <template v-slot:default>
          <thead class="thead-light">
            <tr>
              <th class="text-left ps-6">
                Data
              </th>
              <th class="text-left ps-6">
                Type
              </th>
              <th class="text-left">
                Email
              </th>
              <th class="text-left">
                Amount
              </th>
              <th class="text-left">
                Status
              </th>
            </tr>
          </thead>
          <tbody v-for="(item, i) in items.slice(pageStart, pageStart + countOfPage)" :key="item.id">

            <tr>
              <td :class="[!item.hasOwnProperty('comment') ? 'border-bottom' : 'border-bottom-0']">
                <span class="text-body text-h5">
                  {{ formatDate(item.timestamp) }}
                </span>
              </td>

              <td :class="[!item.hasOwnProperty('comment') ? 'border-bottom' : 'border-bottom-0']" class="text-body text-h5">
                <img v-if="item.kind === 'paypal'"
                  width="68"
                  height="18"
                  v-bind:src="paypalImg"
                  class="me-2"
                >
              </td>

              <td :class="[!item.hasOwnProperty('comment') ? 'border-bottom' : 'border-bottom-0']">
                {{ item.email }}
              </td>

              <td :class="[!item.hasOwnProperty('comment') ? 'border-bottom' : 'border-bottom-0']">
                <div v-if="!allowedToEdit">
                  {{ formatCurrency2(item.amount) }}
                </div>
                <div class="transaction-input py-4" v-else>
                  <v-text-field
                    class="input-style font-size-input placeholder-light"
                    dense
                    flat
                    filled
                    solo
                    prefix="$"
                    height="43"
                    :value="item.amount"
                    type="number"
                    @input="setPrice(item)"
                    :id="'amount_' + i"
                    v-model="item.amount"
                  >
                  </v-text-field>
                </div>
              </td>

              <td :class="[!item.hasOwnProperty('comment') ? 'border-bottom' : 'border-bottom-0']">
                <div v-if="!allowedToEdit">
                  {{ item.status }}
                </div>
                <div class="transaction-input py-4" v-else>
                  <v-select
                    class="input-style font-size-input placeholder-light"
                    dense
                    flat
                    filled
                    solo
                    height="43"
                    :items="statuts"
                    :value="item.status"
                    @input="setStatus(item)"
                    :id="'status_' + i"
                    v-model="item.status"
                  ></v-select>
                </div>
              </td>
            </tr>

            <tr v-if="item.hasOwnProperty('comment')">
              <td class="border-bottom"></td>
              <td class="border-bottom" colspan="4">
                <div v-if="!allowedToEdit" class="py-2">
                  {{ item.comment }}
                </div>
                <div v-else class="py-2">
                  <v-textarea
                    class="input-style font-size-input placeholder-light"
                    dense
                    flat
                    filled
                    solo
                    :value="item.comment"
                    rows="2"
                    type="text"
                    @blur="setComment(item)"
                    :id="'comment_' + i"
                    v-model="item.comment"
                  >
                  </v-textarea>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <div v-if="items.length > 10" class="card-padding d-flex justify-end">
      <nav aria-label="Table navigation">
        <ul class="d-flex justify-content-end custom-pagination">
          <li 
          class="page-item">
          <v-btn
            class="mx-2 custom-pagination__button"
            :class="{'disabled': (currPage === 1)}" 
            width="40"
            height="40"
            @click.prevent="setPage(1)">
            <i aria-hidden="true" class="v-icon notranslate fa fa fa fa-angle-left theme--light"></i>
            <i aria-hidden="true" class="v-icon notranslate fa fa fa fa-angle-left theme--light"></i>
          </v-btn>
          </li>
          <li 
          class="page-item">
          <v-btn
            class="mx-2 custom-pagination__button"
            :class="{'disabled': (currPage === 1)}" 
            width="40"
            height="40"
            @click.prevent="setPage(currPage-1)">
            <i aria-hidden="true" class="v-icon notranslate fa fa fa fa-angle-left theme--light"></i>
          </v-btn>
          </li>
          <li 
          class="page-item" v-for="n in paginationPages">
          <v-btn
            class="mx-2 custom-pagination__button"
            width="40"
            height="40"
            v-bind:class="{'custom-pagination__button--active': (currPage === (n))}" 
            @click.prevent="setPage(n)">
              {{n}}
            </v-btn>
          </li>
          <li 
          class="page-item">
            <v-btn
              class="mx-2 custom-pagination__button"
              :class="{'disabled': (currPage === totalPage)}" 
              width="40"
              height="40"
              @click.prevent="setPage(currPage+1)">
              <i aria-hidden="true" class="v-icon notranslate fa fa fa fa-angle-right theme--light"></i>
            </v-btn>
          </li>
          <li 
          class="page-item">
            <v-btn
              class="mx-2 custom-pagination__button"
              :class="{'disabled': (currPage === totalPage)}" 
              width="40"
              height="40"
              @click.prevent="setPage(totalPage)">
              <i aria-hidden="true" class="v-icon notranslate fa fa fa fa-angle-right theme--light"></i>
              <i aria-hidden="true" class="v-icon notranslate fa fa fa fa-angle-right theme--light"></i>
            </v-btn>
          </li>
        </ul>
      </nav>
    </div>
  </v-card>
</template>
<script>
import { updatePayout } from '@/data.js';
export default {
  name: "user-transaction-table",
  data() {
    return {
      page: 1,
      paypalImg: require("@/assets/img/paypal.svg"),
      statuses: ['Paided', 'Requested', 'Cancelled'],
      countOfPage: 10,
      currPage: 1,
      pages: [],
      statuts: [
        {
          text: 'requested',
          value: 'requested',

        },
        {
          text: 'cancelled',
          value: 'cancelled',
        },
        {
          text: 'paid',
          value: 'paid',
        },
      ],
    };
  },
  computed: {
    pageStart: function(){
      return (this.currPage - 1) * this.countOfPage;
    },
    totalPage: function(){
      return Math.ceil(items.length/this.countOfPage);
    },
    paginationPages: function(){
      this.setPages();
      return this.pages;
    },
    userinfo: function() {
      return window.appData.currentUserInfo;
    },
    items: function() {
      return window.appData.currentUserInfo.transactions.sort(function(a, b) {
        return -(a.timestamp - b.timestamp);
      });
    },
    allowedToEdit: function() {
      return this.$route.meta.isAdminAsUserPage;
    },
  },
  methods: {
    setPages: function(){
      this.pages = [];
      let numberOfPages = Math.ceil(window.appData.currentDashboardData.transactions.length/this.countOfPage);
      if(numberOfPages <= 5) {
        for (let i = 1; i <= numberOfPages; i++) {
          this.pages.push(i);
        }
      } else if(this.currPage === 1) {
        this.pages = [this.currPage, this.currPage + 1, this.currPage + 2, this.currPage + 3, this.currPage + 4];
      } else if(this.currPage === 2) {
        this.pages = [this.currPage - 1, this.currPage, this.currPage + 1, this.currPage + 2, this.currPage + 3];
      } else if(this.currPage === numberOfPages) {
        this.pages = [this.currPage - 4, this.currPage - 3, this.currPage - 2, this.currPage - 1, this.currPage];
      } else if(this.currPage === numberOfPages - 1) {
        this.pages = [this.currPage - 3, this.currPage - 2, this.currPage - 1, this.currPage, this.currPage + 1];
      } else {
        this.pages = [this.currPage - 2, this.currPage - 1, this.currPage, this.currPage + 1, this.currPage + 2];
      }
    },
    formatCurrency2(value) {
      value = Number(value);
      if (value == 0) return `$ 0.00`;
      return `$ ${value.toFixed(2)}`;
    },
    formatDate(value) {
      value = new Date(value);
      const year = value.getFullYear();
      let month = value.getMonth();
      month = String(month).length > 1 ? month : `0${month}`;
      let date = value.getDate();
      date = String(date).length > 1 ? date : `0${date}`;
      const newDate = `${date}.${month}.${year}`;
      return newDate;
    },
    async setPrice(item) {
      item.amount = Number(item.amount);
      await updatePayout(this.userinfo.uid, item.id, {amount: item.amount});
      this.items.find(transaction => transaction.id === item.id).amount = item.amount;
    },
    async setComment(item) {
      await updatePayout(this.userinfo.uid, item.id, {comment: item.comment});
      this.items.find(transaction => transaction.id === item.id).comment = item.comment;
    },
    async setStatus(item) {
      await updatePayout(this.userinfo.uid, item.id, {status: item.status});
      this.items.find(transaction => transaction.id === item.id).status = item.status;
    },
    setPage: function(idx){
      if( idx <= 0 || idx > this.totalPage ){
        return;
      }
      this.currPage = idx;
    },
  },
};
</script>
