<template>
  <v-container fluid class="pa-6">
    <v-row>
      <div class="col col-12">
        <h1 class="title-main">Transactions</h1>
      </div>
      <v-col cols="12">
        <user-transactions-table></user-transactions-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserTransactionsTable from "./Widgets/UserTransactionsTable.vue";

export default {
  name: "UserTransactions",
  components: {
    UserTransactionsTable
  },
};
</script>
